import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: '',
        title: 'Roles y Permisos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: []
    },
    {
        path: '',
        title: 'Mantenimientos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        submenu: [
            {
                path: '',
                title: 'Carreras tecnicas',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: [
                    {
                        path: '',
                        title: 'Level 2',
                        iconType: 'nzIcon',
                        iconTheme: 'outline',
                        icon: '',
                        submenu: []
                    }
                ]
            },
            {
              path: '',
              title: 'Ciclos',
              iconType: '',
              icon: '',
              iconTheme: '',
              submenu: [
                  {
                      path: '',
                      title: 'Level 2',
                      iconType: 'nzIcon',
                      iconTheme: 'outline',
                      icon: '',
                      submenu: []
                  }
              ]
            },
            {
              path: '',
              title: 'Areas de Formacion',
              iconType: '',
              icon: '',
              iconTheme: '',
              submenu: [
                  {
                      path: '',
                      title: 'Level 2',
                      iconType: 'nzIcon',
                      iconTheme: 'outline',
                      icon: '',
                      submenu: []
                  }
              ]
            },
            {
              path: '',
              title: 'Unidades Formativas',
              iconType: '',
              icon: '',
              iconTheme: '',
              submenu: [
                  {
                      path: '',
                      title: 'Level 2',
                      iconType: 'nzIcon',
                      iconTheme: 'outline',
                      icon: '',
                      submenu: []
                  }
              ]
            },
            {
              path: '',
              title: 'Unidades Didacticas',
              iconType: '',
              icon: '',
              iconTheme: '',
              submenu: [
                  {
                      path: '',
                      title: 'Level 2',
                      iconType: 'nzIcon',
                      iconTheme: 'outline',
                      icon: '',
                      submenu: []
                  }
              ]
            },
            {
              path: '',
              title: 'Estado de  Cursos',
              iconType: '',
              icon: '',
              iconTheme: '',
              submenu: [
                  {
                      path: '',
                      title: 'Level 2',
                      iconType: 'nzIcon',
                      iconTheme: 'outline',
                      icon: '',
                      submenu: []
                  }
              ]
            },
            {
              path: '',
              title: 'Tipo de Materia',
              iconType: '',
              icon: '',
              iconTheme: '',
              submenu: [
                  {
                      path: '',
                      title: 'Level 2',
                      iconType: 'nzIcon',
                      iconTheme: 'outline',
                      icon: '',
                      submenu: []
                  }
              ]
            }
        ]
    }
]
